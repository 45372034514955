import React from "react"
import { Helmet } from "react-helmet"
import KdpHeader from "../blocs/Header/Header"
import KdpFooter from "../blocs/Footer/Footer"
import KdpSection1 from "../blocs/Home/Section1"
import KdpSection2 from "../blocs/Home/Section2"
import KdpSection3 from "../blocs/Home/Section3"

export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Découvrez le cabinet CEOS Avocats</title>
        <meta name="description" content="Activité, Associés, Adresse et Contact. Les informations essentielles de CEOS Avocats sont ici" />
      </Helmet>
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpSection1 />
        <KdpSection2 />
        <KdpSection3 />
      </main>
      <footer>
      <KdpFooter />
      </footer>
    </div>
  );
}

